<template>
  <div class="view">
    <div v-if="apiStatus === API_STATUS.PENDING">Loading data...</div>

    <div v-else-if="apiStatus === API_STATUS.ERROR">
      There was a problem while fetching data
    </div>

    <template v-else-if="apiStatus === API_STATUS.SUCCESS">
      <router-link to="/" class="back"
        ><img
          class="desktop_form_close"
          src="@/assets/img/close.svg"
          alt="close"
      /></router-link>

      <!-- <div class="group">
        <label><strong>Producer</strong></label>
        <h1 class="page-title">{{ producerName }}</h1>
      </div> -->
      <BasePageTitle label="Producer" :text="producerName" />

      <div
        v-if="country && country !== 'NULL'"
        class="group-col hide-on-tablet"
      >
        <div class="group">
          <label class="inline"><strong>Country:</strong></label> {{ country }}
        </div>
      </div>

      <div class="headings__container">
        <div class="productions-heading">
          Productions ({{ productionsCount }})
        </div>
        <div class="style-heading">Style</div>
        <div class="status-heading">Status</div>
        <div class="syncs-heading">Syncs</div>
      </div>

      <div class="content__section">
        <div
          class="content__row"
          v-for="item in productions"
          :key="item.objectID"
        >
          <div class="productions__col">
            <SyncImageCell :class="$style.imageCell" :type="item.type" />
            <strong>
              <router-link :to="'/production/' + item.production_id">
                {{ item.production }}
              </router-link>
              <span class="syncs-count-mobile">
                ({{ getProductionCount(item.production_id) }})</span
              >
            </strong>
          </div>

          <div class="style__col">
            {{ convertNullText(item.style) }}
          </div>
          <div class="status__col">
            {{ convertNullText(item.status) }}
          </div>
          <div class="syncs__col">
            {{ getProductionCount(item.production_id) }}
          </div>
        </div>
        <LoadMore v-if="productions.length" @loadMore="loadMore">
          <p v-if="loadMoreStatus === API_STATUS.PENDING">
            Fetching more data...
          </p>
        </LoadMore>
        <div v-if="!productions.length">No results found...</div>
      </div>
    </template>
    <div
      v-if="country && country !== 'NULL'"
      class="mobile-only mobile-country__block"
    >
      <span class="mobile-country-label">Country: </span>
      <span class="mobile-country-text">{{ country }}</span>
    </div>
  </div>
</template>

<script>
import { syncIndex } from "@/services/algoliaService.js";
import { API_STATUS } from "@/constants/apiStatus.js";
import SyncImageCell from "@/components/common/SyncImageCell";
import { LoadMore } from "@/components/common";
import { convertNullText } from "@/helpers";

const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;

export default {
  components: {
    SyncImageCell,
    LoadMore,
  },
  metaInfo: {
    title: "Producer",
  },
  props: {
    producer: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      productions: [],
      productionCountsById: null,
      apiStatus: IDLE,
      loadMoreStatus: IDLE,
      country: "",
      producerName: "",
    };
  },
  computed: {
    productionsCount() {
      if (!this.productionCountsById) return 0;
      return Object.keys(this.productionCountsById).length;
    },
  },
  methods: {
    convertNullText,
    getProductionCount(production_id) {
      return Reflect.get(this.productionCountsById, production_id) ?? 0;
    },
    async loadMore() {
      if (this.loadMoreStatus === PENDING || this.$options.isLastPage) return;

      try {
        this.loadMoreStatus = PENDING;
        this.$options.page++;
        const { hits } = await this.fetchData(
          this.production_id,
          this.$options.page
        );
        if (hits.length) {
          this.productions = [...this.productions, ...hits];
        } else {
          this.$options.isLastPage = true;
        }
        this.loadMoreStatus = SUCCESS;
      } catch (error) {
        console.error(error);
        this.loadMoreStatus = ERROR;
      }
    },
    fetchData(producer, page) {
      return syncIndex.search("", {
        facets: ["production_id", "producer_id"],
        filters: `producer_id: ${producer}`,
        distinct: "1",
        page,
      });
    },
    async fetchProducer(producer, page) {
      try {
        this.apiStatus = API_STATUS.PENDING;
        const result = await this.fetchData(producer, page);
        this.producerName = result.hits?.[0]?.producer;
        this.productionCountsById = result.facets.production_id;
        this.productions = result.hits;
        this.country = result.hits?.[0]?.country;
        this.apiStatus = API_STATUS.SUCCESS;
      } catch (error) {
        console.error(error);
        this.apiStatus = API_STATUS.ERROR;
      }
    },
  },
  created() {
    this.API_STATUS = API_STATUS;
    if (!this.producer) this.$router.push("/");
    this.$options.page = 0;
    this.$options.isLastPage = false;
    this.fetchProducer(this.producer, this.$options.page);
  },
};
</script>

<style lang="scss" module>
.imageCell {
  margin-right: 1.2rem;

  img {
    width: 30px;
  }
}
</style>

<style lang="scss" scoped>
.headings__container,
.content__row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;

  @include tablet-landscape-only {
    grid-template-columns: 3fr 1fr 1fr;
  }
}

.content__row {
  margin-bottom: 1.5rem;
  @include mobile {
    grid-template-columns: 50px 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 0.25rem;
  }
}

.headings__container {
  margin-bottom: 1rem;
  & > div {
    color: $color-primary;
  }

  @include mobile {
    & div:not(.syncs-heading) {
      display: none;
    }

    .syncs-heading {
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 3rem;
    }
  }

  @include tablet-landscape-only {
    margin-bottom: 2rem;
  }
}

.content__section {
  height: 255px;
  overflow: auto;
  margin-bottom: 2rem;
  @include tablet {
    height: 220px;
  }
}

.style-heading,
.style__col {
  @include tablet-landscape-only {
    display: none !important;
  }
}

.syncs-count-mobile {
  font-weight: 400;
  @include tablet {
    display: none;
  }
}

.productions__col {
  display: flex;
  a {
    color: $color-text;
  }
}

.style__col {
  color: $color-primary;
  @include mobile {
    grid-row: 2 / 3;
    grid-column: 2/ 3;
  }
  @include tablet {
    color: $color-text;
  }
}

@include mobile {
  .productions__col {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }
  .status__col,
  .syncs__col {
    display: none;
  }
}

.mobile-country__block {
  display: flex;
  position: fixed;
  bottom: 3rem;
}

.mobile-country-label {
  color: $color-primary;
  margin-right: 1rem;
}

.mobile-country-text {
  font-weight: 500;
}
</style>

